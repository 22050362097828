.certcontent{
    width: 100%;
    height: 100vh;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.certcontent > div{
    background-color: #fff;
    padding: 10px;
}
.viewComment > div > span:first-child{
    font-weight: 500;
}
.viewComment > div > span:nth-child(2){
    margin-left: 10px;
}