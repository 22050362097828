.navbar{
    padding: 5px 2% 0px;position: fixed; 
    border-bottom: 1px solid #d9d9d9;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 5px 10px -5px rgba(0,0,0,.5);
}
.rightpanel{
    float:right;
    padding-top: 10px;
}
.navbrand{
  font-size: 30px;
}
.rightpanel span{
    display: inline-block;
    margin-right: 10px; cursor: pointer;
}
.content{
    padding:110px 5% 20px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    overflow-y: scroll;
}
.content > div{
    background-color: #fff;
    padding: 20px;
    border: 1px solid #d9d9d9;
}
.viewComment, .resDiv{
    width: 100%;
}
.resDiv > div{
    overflow-x: auto;
}
.viewComment > div > span:first-child{
    font-weight: 500;
}
.viewComment > div > span:nth-child(2){
    margin-left: 10px;
}
.selectResult{
    width: 350px;
}
.userResult{
    width: 100%;
}
@media print {
  .userResult{
    padding: 30px;
  }
  .exportBtn{
    display: none;
  }
}
.submitButton {
    padding: 7px;
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 18px;
    margin: 0 auto;
    border-radius: 5px;
    justify-content: center;
    align-items: center;color: #ffffff;
    border: 1px solid #41aeb5;
    background-color: #41aeb5; 
  }
  .backBtn{
    margin-left: 40px; cursor: pointer;
  }
  .backBtn span{
    font-size: 11px;
  }
  .notpaid{
    width: 100%; text-align: center;
  }
  .notpaid a{
    color: var(--primary-color);
    text-decoration: underline;
  }
  .notpaid span{
    font-size: 18px;
  }
  .exportBtn{
    float: right;
  }