.main {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y: auto !important;
}


.forgotMain {
  composes: main;
}

.navDiv {
  width: 100%;
}

.loginDiv {
  width: 35%;
  padding: 10px 10px;
}

.heroDiv {
  width: 50%;
  display: sticky;
  min-height: 400px !important;
  background-image: url("../images/students_09_.jpg");
  /* background-image: url("../images/2107.jpg"); */
  /* background-image: url("../images/5293.jpg"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.footer {
  width: 100%;
  color: #000000;
  text-align: center;
  font-weight: lighter;
  font-size: 16px;
  padding: 5px 30px;
  position: relative;
  bottom: 0;
  margin: 0 auto;
}

@media only screen and (min-height: 1079px) and (max-height: 1200px) {
  .main {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .heroDiv {
    background-position: top;
  }
}
/* md */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .loginDiv {
    width: 50% !important;
    margin-left: 0;
  }
  .heroDiv {
    width: 100% !important;
    background-position: top;
  }
}

/* sm */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main {
    justify-content: center !important;
    align-items: center !important;
    /* flex-direction: column; */
  }
  .loginDiv {
    width: 80% !important;
  }
  .navDiv {
    order: 2;
    /* padding-bottom: 0; */
  }
  .heroDiv {
    order: 1;
    height: 400px !important;
    background-position: top;
    width: 100%;
  }
  .loginDiv {
    order: 3;
    /* padding-top: 0; */
  }
  .footer {
    order: 4;
  }
}

/* xs downwards */
@media only screen and (max-width: 767px) {
  .main {
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }
  .navDiv {
    order: 2;
    padding-bottom: 0;
  }
  .heroDiv {
    order: 1;
    height: 300px !important;
    width: 100%;
  }
  .loginDiv {
    order: 3;
    padding-top: 0;
  }
  .footer {
    order: 4;
  }
}

/* xs */
@media only screen and (min-width: 351px) and (max-width: 767px) {
  .loginDiv {
    width: 100% !important;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

@media only screen and (max-width: 350px) {
  .loginDiv {
    width: 100% !important;
    z-index: 1000;
    background-color: rgb(255, 255, 255, 0.7);
  }
}
.owner:hover{
  text-decoration: underline;
  cursor: pointer;
}

.nav {
  position: relative;
  text-align: center;
  margin: 30px auto 10px;
  display: block;
  width: 100%;
}



/* xs downwards */
@media only screen and (max-width: 767px) {
  .nav {
    margin: 10px auto 10px;
  }
}
