* {
  box-sizing: border-box;
}

.h3{
  padding: 20px;
  margin: 0;
  color: var(--textDark);
}

.signIn {
  display: block;
  width: 100%;
  color: #2c2c2c;
  margin: 0;
}

.formParent {
  text-align: left;
  padding: 0;
  border-radius: 20px;
}

.defaultLog {
  width: 100%;
  padding: 20px;
  gap: 20px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}

.inputDiv {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.forgot {
  margin: 0;
  display: inline-block;
  width: 100%;
  color: #56147a;
  font-size: 14px;
  /* font-weight: bold; */
  text-decoration: underline;
  text-align: left !important;
  /* text-align: right !important; */
}

.btnDiv {
  width: 100%;
}

.btnDiv hr {
  color: #000;
}

.notice {
  margin-top: 10px;
  font-size: 12px;
  color: #363738;
  display: block;
  margin: 0 !important;
  font-weight: 100;
  width: 100%;
  font-size: 14px !important;
}

.notice a {
  color: #420f5e;
  font-weight: 650;
}

.littleNotice {
  composes: notice;
  margin-top: 20px !important;
  display: block !important;
}

.submitButton {
  padding: 7px;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  margin: 0 auto;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.submitButton {
  color: #ffffff;
  border: 2px solid var(--secondary-color);
  background-color: var(--secondary-color);
}

.submitButton:disabled {
  cursor: not-allowed;
  background-color: rgb(from var(--secondary-color) r g b / 0.3);
  border-color: rgb(from var(--secondary-color) r g b / 0.3);
}

.submitButton:disabled:hover {
  color: #ffffff;
  background-color: rgb(from var(--secondary-color) r g b / 0.3);
  border-color: rgb(from var(--secondary-color) r g b / 0.3);
}

.submitButton:hover {
  color: var(--secondary-color);
  background-color: #ffffff;
  border-color: var(--secondary-color);
}

.reverseButton {
  color: #420f5e;
}

/* md */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .type {
    justify-content: space-between;
  }
  .typeLabel {
    width: 48%;
  }
}

/* sm */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .signIn {
    text-align: center !important;
  }
}

/* xs */
@media only screen and (min-width: 351px) and (max-width: 767px) {
  .signIn {
    text-align: center !important;
  }
  .type label {
    width: 100% !important;
  }
}

@media only screen and (max-width: 350px) {
  .signIn {
    text-align: center !important;
  }
}
