:root{
    --primary-color: #811eba;
    --secondary-color: #41aeb5;
    --light-mode-color: #fff;
    --dark-mode-color:#141423;
    --light-mode-text-color: #333;
    --dark-mode-text-color: #fdfdfd;
    --very-light-gray: rgba(242,242,242,0.5);
    --light-gray: #f2f2f2;
    --gray: #d9d9d9;
    --light-dark: rgba(0,0,0,0.4);
    --nav-color: #a2a3b7;
    --nav-highlighted: #fff;
    --border-color: #d9d9d9;
}