.logo_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo_container > span{
    color:var(--primary-color);
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
}