.table input {
    padding: 5px;
    font-size: 16px;
    margin: 5px 0 0;
    color: var(--textDark);
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--border-color);
}

.table input[type="number"] {
    width: 80px;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table thead tr th {
    background-color: var(--border-color);
    padding: 5px;
}

.table tbody tr:nth-child(even) td {
    background-color: #f2f2f2;
}

.table tbody tr td {
    padding: 5px;
}

.colDiv {
    overflow-x: scroll;
}