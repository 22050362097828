.inputLabel {
  color: #3c3c3c;
  width: 100%;
  margin-bottom: 3px;
}

.text{
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}

.formInput {
  height: 35px;
  width: 100%;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  color: #363738;
  padding: 5px;
  font-size: 13px;
}

.asterisk {
  color: var(--primary-color);
  font-weight: bold;
}
